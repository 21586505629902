
import img1 from '../images/svg/icon-1.svg'
import img2 from '../images/svg/icon-2.svg'
import img3 from '../images/svg/icon-3.svg'



const dataPortfolio3 = [

    {
        id: 1,
        img: img1,
        title: 'Stay updated',
        desc: 'Stay updated with ongoing SAP trends'
    },
    {
        id: 2,
        img: img2,
        title: 'Minimize Costs',
        desc: 'Avoid higher fees in the future by configuring outdated SAP versions.'
    },
    {
        id: 3,
        img: img3,
        title: 'Optimize Operations',
        desc: 'Embed standard SAP solutions and partake in custom upgrades.'
    },
    // {
    //     id: 4,
    //     img: img4,
    //     title: 'Performance Measurement',
    //     desc: 'Test the implemented modules for performance'
    // },

    // {
    //     id: 5,
    //     img: img4,
    //     title: 'Final Optimizations',
    //     desc: 'Calibrate the SAP modules for maximum efficiency before product handover.'
    // },

    




]

export default dataPortfolio3;