import React , {useState} from 'react';

import { Accordion } from 'react-bootstrap-accordion';
import { Link } from 'react-router-dom';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';



function FaqPage(props) {
    const {data} = props;

    const [dataTab] = useState([
        {
            id: 1,
            title: '2D Animation',
        },
        {
            id: 2,
            title: 'AD Films',
        },
        {
            id: 3,
            title: 'Collection',
        },
        {
            id: 4,
            title: 'Branding & Communication',
    
        },
        {
            id: 5,
            title: 'Branding',
    
        },
        {
            id: 6,
            title: 'Corporate Presentation',
    
        },
    
        {
            id: 7,
            title: 'Digital Creatives',
    
        },
    
        {
            id: 8,
            title: 'GIF Animations',
    
        },
    
        {
            id: 9,
            title: 'Graphic Design',
    
        },
    
        {
            id: 10,
            title: 'Logo Design',
    
        },
    
        {
            id: 11,
            title: 'Marketing Collaterals',
    
        },
    
        {
            id: 12,
            title: 'Newspaper Advertisements',
    
        },
    
        {
            id: 13,
            title: 'Outdoor Creatives',
    
        },
    
        {
            id: 14,
            title: 'Packaging',
    
        },
    
        {
            id: 15,
            title: 'Radio Scripts',
    
        }
        ,
    
        {
            id: 16,
            title: 'Social Media Content',
    
        },
    
        {
            id: 17,
            title: 'UI UX Design',
    
        },
    
        {
            id: 18,
            title: 'Video Production',
    
        },
    
        {
            id: 19,
            title: 'Website Design',
    
        },
    
        {
            id: 20,
            title: 'Website App',
    
        }

    ]);
    return (
        <section className="faq">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center">
                                <h6 className="sub-heading"><span>Services</span></h6>
                                <h3 className="heading">Where Excellence meet
                                    <br />
                                    Service</h3>

                                   
                            </div>

                            <div className="faq__main flat-tabs">

                            <Tabs>
                            <TabList  className='menu-tab'>
                                
                                {
                                    dataTab.map(idx => (
                                        <Tab className='fs-14 h6' key={idx.id}>{idx.title}</Tab>
                                    ))
                                }
                                
                            </TabList>


                                
                            </Tabs> 

                        </div>

                            
                        </div>

                    </div>
                </div>
            </section>
    );
}

export default FaqPage;