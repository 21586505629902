
import img1 from '../images/icon/icon25.svg'
import img2 from '../images/icon/icon15.1.svg'
import img3 from '../images/icon/icon16.1.svg'
import img4 from '../images/icon/icon17.1.svg'
import img5 from '../images/icon/icon18.1.svg'
import img6 from '../images/icon/icon19.1.svg'


const dataBox = [

    {
        id: 1,
        img: img1,
        title: 'Flexibility',
        desc: "With SAP S/4HANA solutions, you can tailor your app to suit your business's specific needs, ensuring highly responsive features regardless of your industry."
    },
    {
        id: 2,
        img: img2,
        title: 'Enhanced App Development',
        desc: 'The architecture provided by S/4HANA fosters a seamless user experience, promoting long-term engagement with your application.'
    },
    {
        id: 3,
        img: img3,
        title: 'Flawless Execution:',
        desc: 'Implement your innovative ideas into the app architecture without compromising on performance or functionality, ensuring a smooth user experience'
    },
    {
        id: 4,
        img: img4,
        title: 'Seamless User Experience',
        desc: 'Migrating to S/4 HANA guarantees an exceptional and seamless user experience for all your business applications, enhancing usability and efficiency.'
    },

    {
        id: 5,
        img: img5,
        title: 'Cutting-edge Interface',
        desc: 'Meet all user requirements with a creative and intuitive interface crafted by our team of S/4 HANA experts, ensuring a modern and appealing design.'
    },

    {
        id: 6,
        img: img6,
        title: 'User-Centric Approach',
        desc: 'Utilize modern solutions to address user needs effectively, enhancing user satisfaction and overall app reception'
    },


]

export default dataBox;