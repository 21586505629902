import React from 'react';
import Project2 from '../components/project/Project2';
import dataFaqs from '../assets/fake-data/data-faq';
import dataItem from '../assets/fake-data/data-item';
import Footer2 from '../components/footer/Footer2';
import PageTitle9 from '../components/pagetitle/PageTitle9';
import Faqs from '../components/faqs/Faqs';
import Faq3 from '../components/faqs/Faq3';

function NavService(props) {
    return (
        <div className='page-roadmap wrapper'>

        <PageTitle9 title='Services' />


        <Project2 data={dataItem} /> 

        <Faq3 data={dataFaqs} />
        <Footer2 />
            
        </div>
    );
}

export default NavService;