
import img1 from '../images/svg/icon-1.svg'
import img2 from '../images/icon/icon24.svg'
import img3 from '../images/svg/icon-3.svg'
import img4 from '../images/svg/icon-6.svg'
import img5 from '../images/svg/icon-5.svg'



const dataPortfolio7 = [

    {
        id: 1,
        img: img1,
        title: 'Mission-driven Innovation',
        desc: 'We are on a mission to redefine how professionals across various sectors perform daily tasks. '
    },
    {
        id: 2,
        img: img3,
        title: 'Diverse Expertise',
        desc: 'Our dynamic team comprises technologists, data scientists, and industry experts. '
    },
    {
        id: 3,
        img: img2,
        title: 'Industry Impact',
        desc: 'We specialize in crafting AI solutions for a wide range of sectors.'
    },
    {
        id: 4,
        img: img4,
        title: 'Commitment to Excellence',
        desc: 'We are committed to delivering tangible value and results to our clients.'
    },

    {
        id: 5,
        img: img5,
        title: 'Vision for the Future',
        desc: 'We envision a world where AI-powered tools are synonymous with efficiency, productivity, and success.'
    },



]

export default dataPortfolio7;