import React , {useState} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '../button/Button';

Project2.propTypes = {
    data: PropTypes.array
};

function Project2(props) {
    const {data} = props;

    const [dataBlock] = useState(
        {
            subheading: 'Services',
            heading: 'Our Technology Services',
            
        }
    )
    return (
        <section id="services" className="project s2">
                    <div className="shape right"></div>
                    <div  className="container">
                        <div  className="row ">
                            <div className="col-12">
                                <div className="block-text center">
                                    <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                    <h3 className="heading " >{dataBlock.heading}</h3>
                                </div>
                            </div>

                            {
                                data.map(idx => (
                                    <div key={idx.id} className="col-xl-3 col-md-3">
                                        <div  className="project-box">
                                            <div className="image">
                                                <Link to="/products">
                                                    <img src={idx.img} alt="Trigeminal" />
                                                </Link>
                                            </div>
                                            <div className="content">
                                                <Link to="/products" style={{color:"#1E71A1"}} className="h5 title">{idx.title}</Link>
                                            </div>
                                        </div>


                                        
                                    </div>

                                    
                                ))
                            }


                        

                        </div>
                    </div>
                </section>
    );
}

export default Project2;