import React from 'react';
import img1 from '../assets/images/background/bg-banner.png'

const LoginPage = () => {
  return (
    <div style={{
      width: '100%', // Adjust the width to cover the entire screen
      height: '90vh', // Adjust the height to cover the entire viewport
      backgroundImage: `url(${img1})`, // Specify the background image
      backgroundSize: 'cover', // Cover the entire background
      backgroundPosition: 'center', // Center the background image
      justifyContent: 'center',
      alignItems: 'center',
    }}>
     <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding:"10px"
    }}>
      <div style={{
        width: '400px',
        border:"2px solid #dddd",
        borderRadius:'25px',
        padding:"40px",
        marginTop:"100px"
       
      }}>
        <h4 style={{ textAlign: 'center',color:"white",marginBottom:"30px" }}>Login</h4>
        <form style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <label style={{
            marginBottom: '5px',
            textAlign: 'start',
            color:"white"
          }} htmlFor="username">Username</label>
          <input style={{
            marginBottom: '15px',
            padding: '8px',
            borderRadius: '3px',
            border: '1px solid #ccc',
            
          }} type="text" id="username" name="username" />

          <label style={{
            marginBottom: '5px',
            textAlign: 'left',
            color:"white"
          }} htmlFor="password">Password</label>
          <input style={{
            marginBottom: '15px',
            padding: '8px',
            borderRadius: '3px',
            border: '1px solid #ccc',
          }} type="password" id="password" name="password" />

          <button style={{
            backgroundColor: '#007bff',
            color: '#fff',
            padding: '10px',
            borderRadius: '3px',
            border: 'none',
            cursor: 'pointer',
            marginTop:"30px"
          }} type="submit">Login</button>
        </form>
      </div>
    </div>
    </div>
  );
};

export default LoginPage;
