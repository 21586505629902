import React from 'react';
import About7 from '../components/about/About7';
import Counter from '../components/counter/Counter';
import Team from '../components/team/Team';
import dataTeam from '../assets/fake-data/dataTeam';
import Button from '../components/button/Button';
import img from '../assets/images/layouts/create.png'
import PageTitle8 from '../components/pagetitle/PageTitle8';
import Portfolio6 from '../components/portfolio/Portfolio6';
import dataPortfolio7 from '../assets/fake-data/data-portfolio7';
import Footer2 from '../components/footer/Footer2';



function About(props) {
    return (
        <div className='page-about wrapper'>

            <PageTitle8 title='About' />

            <About7/>

            <Counter />  

            <Portfolio6  data={dataPortfolio7} />

            <Team data={dataTeam} />

            <section className="create" id="contacts">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="create__main">
                                <div className="content">
                                    <h4 className="heading">Create your NFT portfolio</h4>
                                    <p>Get updated with news, tips & tricks</p>
                                    <Button title='Join Now' link='/contact' />
                                
                                </div>
                                <img src={img} alt="Trigeminal" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer2 />
            
        </div>
    );
}

export default About;