


const dataFaqs = [
    {
        id: 1,
        title: 'What industries do your AI-powered apps cater to?',
        text : 'Our AI-powered apps are designed for a diverse range of industries including medical diagnostics, microbiology, predictive maintenance, financial forecasting, e-commerce optimization, logistics management, agricultural advancements, and urban planning, among others. Our goal is to be as inclusive as possible, catering to the unique needs of each sector.',
        show: ''
    
    },
    {
        id: 2,
        title: 'How can AI enhance the productivity of professionals?',
        text : 'AI can analyze vast amounts of data faster than any human, recognize patterns, and make data-driven predictions or decisions. By incorporating AI into their daily tasks, professionals can offload repetitive or data-heavy tasks, allowing them to focus on more complex, value-added activities. This not only streamlines operations but also minimizes errors and enhances overall efficiency.',
        show: ''
    
    },
    {
        id: 3,
        title: 'Is integrating AI apps with existing systems complicated?',
        text : 'We prioritize user experience and seamless integration. Our AI apps are designed to be compatible with a wide range of existing systems. Additionally, our dedicated support team is always on hand to guide you through the integration process, ensuring a smooth transition.',
        show: ''
    
    },
    {
        id: 4,
        title: 'How do you ensure the security of data in AI applications?',
        text : 'Data security and privacy are of paramount importance to us. We employ state-of-the-art encryption methods and follow industry best practices to safeguard all data. Our applications are designed with built-in security features, and we continually update them to counter emerging threats.',
        show: ''
    
    },
    {
        id: 5,
        title: 'Training programs available for professionals new to AI?',
        text : 'Absolutely! We believe in empowering our users to make the most of our applications. We offer comprehensive training programs and tutorials to help professionals, irrespective of their familiarity with AI, to use our apps effectively.',
        show: ''
    
    },
    {
        id: 6,
        title: 'What makes your company stand out in the crowded AI market?',
        text : 'Our unique blend of cutting-edge technology, industry expertise, and a relentless focus on user needs sets us apart. We are not just about creating AI tools; we are about creating solutions that truly resonate with the challenges and aspirations of professionals across various industries.',
        show: ''
    
    },
    {
        id: 7,
        title: 'How can I get a demo or trial of your AI applications?',
        text : "We would be delighted to showcase our solutions to you. Simply reach out to our customer support team or fill out the inquiry form on our website, and we'll arrange a personalized demo or trial based on your needs",
        show: ''
    
    },
    {
        id: 8,
        title: 'Are your AI solutions customizable to specific industry needs?',
        text : 'Yes, we understand that one size does not fit all. Our solutions can be tailored to meet the unique requirements and challenges of your industry, ensuring maximum relevance and impact',
        show: ''
    
    },


   

   
]

export default dataFaqs;