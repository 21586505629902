import React , {useState} from 'react';
import PropTypes from 'prop-types';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

Team.propTypes = {
    data: PropTypes.array
};

function Team(props) {

    const {data} = props;

    const [dataBlock] = useState(
        {
            subheading: 'Team Leaders',
            heading: 'Our Amazing Team Leaders',
        }
    )

    return (
        <section id="team" className="team bottom">
            <div className="container bottom">
                <div className="row bottom">
                    <div className="col-12 bottom">
                        <div className="block-text center">
                            <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                            <h3 className="heading pd">{dataBlock.heading}</h3>
                        </div>

                        <Swiper
                                className="team-swiper col-lg-6 col-md-12 col-sm-12 "
                                spaceBetween={20}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                        },
                                    0: {
                                        slidesPerView: 2,
                                    },
                                    
                                }}
                                loop={true}
                                
                            >

                                {
                                    data.map(idx => (
                                        <SwiperSlide  key={idx.id}>
                                            <div className="swiper-slide ">
                                                <div className="team-box">
                                                    <div className="image">
                                                        <a >
                                                            <img src={idx.img} alt="Trigeminal" />
                                                        </a>
                                                    </div>
                                                    <div className="content">
                                                        <a className="h5 name">{idx.name}</a>
                                                        <p className="postion">
                                                            {idx.position}
                                                        </p>

                                                        <ul className="list-social">
                                                            {idx.link1 &&<li><a target='_blank' href={idx.link1}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="30px" viewBox="0 0 24 24"><path fill="#ffff" d="M6.94 5a2 2 0 1 1-4-.002a2 2 0 0 1 4 .002M7 8.48H3V21h4zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91z"/></svg>  
                                                            </a></li>}
                                                           {idx.link2 && <li><a target='_blank' href={idx.link2}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="30px" viewBox="0 0 24 24"><path fill="white" d="M14.2 2.875A4.625 4.625 0 0 0 9.575 7.5v2.575H7.1c-.124 0-.225.1-.225.225v3.4c0 .124.1.225.225.225h2.475V20.9c0 .124.1.225.225.225h3.4c.124 0 .225-.1.225-.225v-6.975h2.497c.103 0 .193-.07.218-.17l.85-3.4a.225.225 0 0 0-.218-.28h-3.347V7.5a.775.775 0 0 1 .775-.775h2.6c.124 0 .225-.1.225-.225V3.1c0-.124-.1-.225-.225-.225z"/></svg>
                                                            </a></li>}
                                                            {idx.link3 &&<li><a target='_blank' href={idx.link3}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="30px" viewBox="0 0 24 24"><path fill="white" d="M19.05 4.91A9.816 9.816 0 0 0 12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01m-7.01 15.24c-1.48 0-2.93-.4-4.2-1.15l-.3-.18l-3.12.82l.83-3.04l-.2-.31a8.264 8.264 0 0 1-1.26-4.38c0-4.54 3.7-8.24 8.24-8.24c2.2 0 4.27.86 5.82 2.42a8.183 8.183 0 0 1 2.41 5.83c.02 4.54-3.68 8.23-8.22 8.23m4.52-6.16c-.25-.12-1.47-.72-1.69-.81c-.23-.08-.39-.12-.56.12c-.17.25-.64.81-.78.97c-.14.17-.29.19-.54.06c-.25-.12-1.05-.39-1.99-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.14-.25-.02-.38.11-.51c.11-.11.25-.29.37-.43s.17-.25.25-.41c.08-.17.04-.31-.02-.43s-.56-1.34-.76-1.84c-.2-.48-.41-.42-.56-.43h-.48c-.17 0-.43.06-.66.31c-.22.25-.86.85-.86 2.07c0 1.22.89 2.4 1.01 2.56c.12.17 1.75 2.67 4.23 3.74c.59.26 1.05.41 1.41.52c.59.19 1.13.16 1.56.1c.48-.07 1.47-.6 1.67-1.18c.21-.58.21-1.07.14-1.18s-.22-.16-.47-.28"/></svg>
                                                            </a></li>}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }
                               
                            </Swiper>



                        

                    </div>

                </div>
            </div>

            
                                
        </section>

        
    );
}

export default Team;