import React , {useState} from 'react';
import Button from '../button/Button';
import Partner from '../partner/Partner';


function About4(props) {

    const [dataBlock] = useState(
        {
            subheading: 'About',
            heading: 'SAP Consulting Services',

            desc1: "Are you ready to propel your business into the future?  ",
            desc2: 'Embrace seamless digital transformation with our top-tier SAP consulting services. Our seasoned SAP consultants possess the precise expertise to navigate your business through the complexities of implementation and optimization.',
            desc3: "In today's digital landscape, SAP consulting is instrumental in deploying compatible solutions that propel organizations forward. Leveraging artificial intelligence and robust data handling features, SAP revolutionizes business processes across various domains.",
            desc4:"From Human Resources to Supply Chain Management, SAP empowers organizations with unparalleled analysis, reporting, and predictive capabilities, enabling data-driven decision-making in real time."
        }
    )
    return (
        <section className="about s2">
                    <div className="shape"></div>
                    <div className="shape right"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="block-text center pd-0">
                                    <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                    <h3 className="heading pd">{dataBlock.heading}</h3>
                                        
                                </div>

                                <div className="about__main center" data-aos="fade-up" data-aos-duration="3000">
                                    <img src="https://trigeminal.blr1.cdn.digitaloceanspaces.com/www/Trigeminal.ai/sap-consulting.png" alt="Trigeminal" />
                                    <p className="mb-17">{dataBlock.desc1}</p>
                                    <p className="mb-30">{dataBlock.desc2}</p>
                                    <h6 className="sub-heading mb-17"><span>How SAP Consulting Helps</span></h6>
                                    <p className="mb-30">{dataBlock.desc3}</p>

                                    <p className="mb-30">{dataBlock.desc4}</p>
                                    <Button title='More About Us' link='/support' />
                                </div>

                                <Partner />
                            </div>
                        </div>
                    </div>
                </section>
    );
}

export default About4;