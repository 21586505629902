import React from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../assets/images/layouts/banner.png'
import img4 from '../../assets/images/layouts/banner-sm.jpg'
import img2 from '../../assets/images/layouts/gen.png'
import img3 from '../../assets/images/layouts/avt-01.png'


function Banner(props) {
    return (
        <section className="banner">
                <div className="shape right"></div>
                <div className="container big">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="banner__left">
                                <div className="block-text">
                                    <h2 className="heading">Innovate  <br />
                                    with Apps,<span  className="s1 arlo_tm_animation_text_word">&nbsp;AI</span> <br />
                                    and Automation  </h2>
                                    <p className="desc">At Trigeminal.ai, we understand that your business transformation journey begins with   vision. 

</p>

                                    <a  href="https://wa.me/918310217802" target='_blank'  className="action-btn"><span>WhatsApp Us</span></a>
                                </div>

                                <div className="pay">
                                    <h6>Our Platforms</h6>

                                    <div className="list">

                                        <ul >
                                            <li><a className='platform-logo'><img src="/collabs/image-1.png" style={{width:"35px",}} alt='platforms'/></a></li>
                                            <li><a className='platform-logo'><img src="/collabs/image-2.png" style={{width:"35px"}} alt='platforms'/></a></li>
                                            <li><a className='platform-logo'><img src="/collabs/image-3.png" style={{width:"35px"}} alt='platforms'/></a></li>
                                            <li><a className='platform-logo'><img src="/collabs/image-4.png" style={{width:"35px"}} alt='platforms'/></a></li>
                                            <li><a className='platform-logo'><img src="/collabs/image-5.png" style={{width:"35px"}} alt='platforms'/></a></li>
                                            <li><a  className='platform-logo'><img src="/collabs/image-6.png" style={{width:"35px"}} alt='platforms'/></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                        <div  className="col-xl-6 col-md-12">

                            <div className="banner__right  ">
                                <div className="image-sm">
                                    <img src={img4} alt="Trigeminal" />
                                </div>
                                <div className="image-lg">
                                    <img src={img1} alt="Trigeminal" />
                                </div>


                                <div className="price">
                                    <div className="icon">
                                        <img  style={{borderRadius:"50%"}} src={img2} alt="Trigeminal" />
                                    </div>
                                    <div className="content">
                                        <p>Powering Future</p>
                                        <h5>AI Softwares</h5>
                                    </div>
                                </div>

                                <div className="owner">
                                    <div className="image">
                                        <img src={img3} alt="Trigeminal" />
                                    </div>
                                    <div className="content">
                                        <h5>AI solutions </h5>
                                        <p> Simplify with Confidence</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default Banner;