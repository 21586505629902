import React from "react";
import Footer2 from "../components/footer/Footer2";
import PageTitle11 from "../components/pagetitle/PageTitle11";

function Refund(props) {
  return (
    <div className="wrapper page-help">
      <PageTitle11 title="Refund Policy" />

      <section className="watch-video">
        <div className="shape"></div>
        <div className="container">
          <div className="row">
            <div className="col-12">
            

            <div className="bottom">
  <h6>
    At Trigeminal.ai, we are committed to ensuring your satisfaction with our products. However, if you are not completely satisfied with your purchase, we're here to help.
    Please review our refund policy below.
  </h6>

  {/* Uncomment this line if needed */}
  {/* <p className="bottom">Note: the privacy practices set forth in this privacy policy are for this website only. If you link to other websites, please review the privacy policies posted at those sites.</p> */}
</div>

<div className="bottom">
  <h5>1. Eligibility for Refunds</h5>
  <p className="bottom">
    We offer refunds for software purchases under the following conditions:
  </p>
  
  <ul className="ml-20">
    <li>
      <b>•&nbsp;&nbsp;&nbsp;Digital Products: </b> 
      If you experience technical issues that prevent the software from functioning as described, and our support team is unable to resolve the issue within 14 days of purchase.
    </li>
    <li>
      <b>•&nbsp;&nbsp;&nbsp;Subscriptions: </b>
      Refunds are available for subscription-based services within the first 7 days of the initial purchase. After this period, no refunds will be provided for subscription fees.
    </li>
    <li>
      <b>•&nbsp;&nbsp;&nbsp;Non-Refundable Products: </b>
      Custom software development, consulting services, or any personalized services are non-refundable.
    </li>
  </ul>
</div>


              <div className="bottom">
                <h5>2. Requesting a Refund</h5>
                <p className="bottom">
                To request a refund, please follow these steps:                </p>
                <p>
                      <ul class="ml-20">
                    <li>
                      
                       <b>•&nbsp;&nbsp;&nbsp;Contact Support:  </b>  Email our support team at Info@trigeminal-ai.com within the eligible refund period. Include your purchase details, the reason for your refund request, and any relevant screenshots or documentation.
                    
                    </li>
                    <li>
                      
                       <b>•&nbsp;&nbsp;&nbsp;Evaluation: </b> Our team will evaluate your request and may contact you for additional information. We aim to resolve all refund requests within 5-7 business days.
                    
                    </li>
                    <li>
                      
                       <b>•&nbsp;&nbsp;&nbsp;Approval: </b> If your refund request is approved, the refund will be processed, and a credit will be applied to your original method of payment within 10-15 business days.
                    
                    </li>
                  </ul>
                     </p>

                <div className="bottom">
                  <h5>3. Exchanges</h5>

                  <p className="bottom"> If you encounter issues with a product that can be resolved by an exchange or an upgrade, we may offer an alternative solution instead of a refund. Please contact our support team to discuss available options.</p>
                </div>

                <div className="bottom">
                    <h5>4. Chargebacks</h5>

                    <p className="bottom">We encourage you to contact us directly before initiating a chargeback with your credit card company. Chargebacks are a time-consuming process, and we are committed to resolving any issues directly and amicably.</p>
                </div>

                <div className=" bottom">
                    <h5>5. Changes to This Policy</h5>

                    <p className="bottom">We reserve the right to update or modify this Refund Policy at any time. Changes will be posted on this page. We encourage you to review this policy periodically to stay informed.</p>
                </div>
                <div className=" bottom">
                    <h5>6. Contact Us</h5>

                    <p className="bottom">If you have any questions or concerns about our refund policy, please contact us at:</p>
                    <ul class="ml-20">
                      <li>
                        
                         <b>•&nbsp;&nbsp;&nbsp;Email:  </b>  Info@trigeminal-ai.com
                      
                      </li>
                      <li>
                        
                         <b>•&nbsp;&nbsp;&nbsp;Address:  </b> Koramangala 5th Block, Bengaluru
                      
                      </li>
                    </ul>      
                </div>

               
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer2 />
    </div>
  );
}

export default Refund;
