import React from "react";
import Footer2 from "../components/footer/Footer2";
import PageTitle11 from "../components/pagetitle/PageTitle11";

function Terms(props) {
  return (
    <div className="wrapper page-help">
      <PageTitle11 title="Terms & Conditions" />

      <section className="watch-video">
        <div className="shape"></div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="block-text center">
            
               
              </div>

              <div className="bottom">
                <h6>
                  Welcome to Trigeminal.ai. Thanks for using our products
                  and services.
                </h6>
                <p className="bottom">
                  By using our Services, you are agreeing to these terms. Please
                  read them carefully.
                </p>
                <p className="bottom">
                  Our Services are very diverse, so sometimes additional terms
                  or product requirements (including age requirements) may
                  apply. Additional terms will be available with the relevant
                  Services, and those additional terms become part of your
                  agreement with us if you use those Services.
                </p>{" "}
              </div>
              <div className="bottom">
                <h5>Using our Services</h5>
                <p className="bottom">
                  We collect personally identifiable information, like names,
                  postal addresses, email addresses, etc. when voluntarily
                  submitted by our visitors. This information is only used to
                  fulfil your specific request, unless you give us permission to
                  use it in another manner, for example, to add you to one of
                  our mailing lists.
                </p>
              </div>

              <div className="bottom">
                <h5>Cookie/Tracking Technology</h5>
                <p className="bottom">
                  You must follow any policies made available to you within the
                  Services. Don’t misuse our Services. For example, don’t
                  interfere with our Services or try to access them using a
                  method other than the interface and the instructions that we
                  provide. You may use our Services only as permitted by law,
                  including applicable export and re-export control laws and
                  regulations. We may suspend or stop providing our Services to
                  you if you do not comply with our terms or policies or if we
                  are investigating suspected misconduct. Using our Services
                  does not give you ownership of any intellectual property
                  rights in our Services or the content you access. You may not
                  use content from our Services unless you obtain permission
                  from its owner or are otherwise permitted by law. These terms
                  do not grant you the right to use any branding or logos used
                  in our Services. Don’t remove, obscure, or alter any legal
                  notices displayed in or along with our Services. Our Services
                  display some content that is not ours. This content is the
                  sole responsibility of the entity that makes it available. We
                  may review content to determine whether it is illegal or
                  violates our policies, and we may remove or refuse to display
                  content that we reasonably believe violates our policies or
                  the law. But that does not necessarily mean that we review
                  content, so please don’t assume that we do. In connection with
                  your use of the Services, we may send you service
                  announcements, administrative messages, and other information.
                  You may opt out of some of those communications.
                </p>

                <div className="bottom">
                  <h5>Privacy and Copyright Protection</h5>

                  <p className="bottom">
                    Our privacy policy explains how we treat your personal data
                    and protect your privacy when you use our Services. By using
                    our Services, you agree that securities can use such data in
                    accordance with our privacy policies. We respond to notices
                    of alleged copyright infringement and terminate accounts of
                    repeat infringes. We provide information to help copyright
                    holders manage their intellectual property online. If you
                    think somebody is violating your copyrights and want to
                    notify us, you may do so by contacting us.
                  </p>
                </div>

                <div className="bottom">
                  <h5>Your Content in our Services</h5>

                  <p className="bottom">
                    Some of our Services allow you to submit content. You retain
                    ownership of any intellectual property rights that you hold
                    in that content.
                     The purpose of hosting
                    and storing the content is to enable you to use our Services
                    as best as possible. We will not use your content for any
                    other purpose whatsoever. Once you cease using our services,
                    you may delete the content that you have uploaded or
                    otherwise submitted as per your discretion.
                  </p>
                </div>

                <div className=" bottom">
                  <h5>About Software in our Services</h5>

                  <p className="bottom">
                    When a Service requires or includes downloadable software,
                    this software may update automatically on your device once a
                    new version or feature is available. Some Services may let
                    you adjust your automatic update settings. This license is for the sole purpose of enabling
                    you to use and enjoy the benefit of the Services as provided
                    by Trigeminal.ai, in the manner permitted by these
                    terms. You may not copy, modify, distribute, sell, or lease
                    any part of our Services or included software, nor may you
                    reverse engineer or attempt to extract the source code of
                    that software, unless laws prohibit those restrictions or
                    you have our written permission. Open-source software is
                    important to us. Some software used in our Services may be
                    offered under an open source license that we will make
                    available to you. There may be provisions in the open-source
                    license that expressly override some of these terms.
                  </p>
                </div>

                <div className="bottom">
                  <h5>Modifying and Terminating our Services</h5>

                  <p className="bottom">
                    We are constantly changing and improving our Services. We
                    may add or remove functionalities or features, and we may
                    suspend or stop a Service altogether. You can stop using our
                    Services at any time, although we’ll be sorry to see you go.
                    We may also stop providing Services to you, or add or create
                    new limits to our Services at any time. We believe that you
                    own your data and preserving your access to such data is
                    important. If we discontinue a Service, where reasonably
                    possible, we will give you reasonable advance notice and a
                    chance to get information out of that Service.
                  </p>
                </div>

                <div className="bottom">
                  <h5>Liability for our Services</h5>
                  <p className="bottom">
                    WHEN PERMITTED BY LAW, WILL NOT BE RESPONSIBLE FOR LOST
                    PROFITS, REVENUES, OR DATA, FINANCIAL LOSSES OR INDIRECT,
                    SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES. TO
                    THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF OUR
                    COMPANY, AND ITS SUPPLIERS AND DISTRIBUTORS FOR ANY CLAIM
                    UNDER THESE TERMS, INCLUDING FOR ANY IMPLIED WARRANTIES, IS
                    LIMITED TO THE AMOUNT YOU PAID US TO USE THE SERVICES (OR,
                    IF WE CHOOSE, TO SUPPLYING YOU THE SERVICES AGAIN).IN ALL
                    CASES
                  </p>
                </div>

                <div className="bottom">
                  <h5>Business uses of our Services</h5>

                  <p className="bottom">
                    If you are using our Services on behalf of a business, that
                    business accepts these terms. It will hold harmless and its
                    affiliates, officers, agents, and employees from any claim,
                    suit or action arising from or related to the use of the
                    Services or violation of these terms, including any
                    liability or expense arising from claims, losses, damages,
                    suits, judgments, litigation costs and attorneys’ fees.
                  </p>
                </div>

                <div className="bottom">
                  <h5>About these Terms</h5>
                  <p className="bottom">
                    We may modify these terms or any additional terms that apply
                    to a Service to, for example, reflect changes to the law or
                    changes to our Services. You should look at the terms
                    regularly. We’ll post notice of modifications to these terms
                    on this page. We’ll post notice of modified additional terms
                    in the applicable Service. Changes will not apply
                    retroactively and will become effective no sooner than
                    fourteen days after they are posted. However, changes
                    addressing new functions for a Service or changes made for
                    legal reasons will be effective immediately. If you do not
                    agree to the modified terms for a Service, you should
                    discontinue your use of that Service.
                  </p>

                  <p className="bottom">
                    If there is a conflict between these terms and the
                    additional terms, the additional terms will control for that
                    conflict. These terms control the relationship between
                    company and you. They do not create any
                    third-party beneficiary rights. If you do not comply with
                    these terms, and we don’t take action right away, this
                    doesn’t mean that we are giving up any rights that we may
                    have (such as taking action in the future).
                  </p>

                  <p className="bottom">For information about how to contact us, please visit our contact page.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer2 />
    </div>
  );
}

export default Terms;
