import React from 'react';
import About6 from '../components/about/About6';
import dataBox5 from '../assets/fake-data/data-box-5';
import Speciality5 from '../components/speciality/Speciality5';
import Portfolio5 from '../components/portfolio/Portfolio5';
import dataPortfolio5 from '../assets/fake-data/data-portfolio5';
import Token2 from '../components/token/Token2';
import PageTitle4 from '../components/pagetitle/PageTitle4';
import Footer2 from '../components/footer/Footer2';





function Support(props) {
  
    return (
        <div className='page-about wrapper'>

            <PageTitle4 title="SAP Support Services" />

            <About6 />


           <Speciality5 data={dataBox5}/>

       
           

            {/* <Counter />   */}

            <Portfolio5 data={dataPortfolio5} />
            <section className="faq s3">
        <div className="container">
            <div className="row">
                <div className="col-xl-12 col-md-12">
                    
                    <div className="block-text center" >
                    <h6 className="sub-heading"><span>Training</span></h6>

                        <h3 className="heading pd">Comprehensive SAP Training Services</h3>
                        <p className="mb-17">Our training services are meticulously crafted to empower our clients in optimizing their implemented solutions and maximizing their investments. Tailored to your company's unique requirements, we provide fully customized courses aligned with your SAP system and business processes.</p>
                        <p className="mb-17">Our seasoned consultants conduct thorough assessments of your organization's needs, ensuring that content is adapted precisely to meet your business objectives and the distinct requirements of each student group.</p>  
                        <p className="mb-17">Furthermore, as esteemed contributors to the SAP Training Center, we offer expert guidance on official SAP courses. We recommend the most suitable courses for your needs and oversee their execution through the SAP Training Department, maintaining seamless communication throughout the process.</p>
                    </div>
                                     
                </div>

               

            </div>
        </div>
    </section>




            <Token2 />

            {/* <Team data={dataTeam} /> */}

            {/* <section className="create">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="create__main">
                                <div className="content">
                                    <h4 className="heading">Let's Talk:</h4>
                                    <p>Ready to kickstart your business transformation <br/>
                                         journey with SAP? Fill in the form below for a free consultation, <br /> and we'll get in touch with you promptly.</p>
                                    <Button title='Contact' link='/contact' />
                                
                                </div>
                                <img src={img} alt="Trigeminal" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

<Footer2 />
            
        </div>
    );
}

export default Support;