import React, { useRef } from 'react';
import Footer2 from "../components/footer/Footer2";
import PageTitle7 from "../components/pagetitle/PageTitle7";
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import styles from './Pricing.module.css';

function Pricing(props) {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('service_rg0r0ym', 'template_fqg7w91', form.current, {
        publicKey: 'cxIEUTKyfzeItouNn',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          form.current.reset();
          Swal.fire({
            title: "Success!",
            text: "Pricing form submitted successfully",
            icon: "success"
          });
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div className="wrapper">
      <PageTitle7 title="Pricing" />

      <section id="get-in-touch" className={styles.touchSection}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={styles.centerText}>
                <h6 className={styles.subHeading}>
                  <span>Pricing Plans</span>
                </h6>
                <h3 className={styles.heading}>
                Choose Your <br />
                Perfect Plan
                </h3>
              </div>
        
              <section id="pricing" className={styles.pricingContent}>
             
                <div className={styles.pricingContent}>
                  {/* Starter Plan */}
                  <div className={styles.pricingDesign}>
                    <div className={styles.singlePricing}>
                      <div className={styles.priceHead}>
                        <h2>Starter</h2>
                        <h1>₹999</h1>
                        <span>/Monthly</span>
                      </div>
                      <ul>
                        <li><b>15</b> websites</li>
                        <li><b>50GB</b> Disk Space</li>
                        <li><b>50</b> Emails</li>
                        <li><b>50GB</b> Bandwidth</li>
                        <li><b>10</b> Subdomains</li>
                        <li><b>Unlimited</b> Support</li>
                      </ul>
                      <a href="#" className={styles.priceBtn} data-price="₹999">Order Now</a>
                    </div>
                  </div>
                  {/* Personal Plan */}
                  <div className={styles.pricingDesign}>
                    <div className={styles.singlePricing}>
                      <div className={styles.priceHead}>
                        <h2>Personal</h2>
                        <h1>₹2999</h1>
                        <span>/Monthly</span>
                      </div>
                      <ul>
                        <li><b>15</b> websites</li>
                        <li><b>50GB</b> Disk Space</li>
                        <li><b>50</b> Emails</li>
                        <li><b>50GB</b> Bandwidth</li>
                        <li><b>10</b> Subdomains</li>
                        <li><b>Unlimited</b> Support</li>
                      </ul>
                      <a href="#" className={styles.priceBtn} data-price="₹2999">Order Now</a>
                    </div>
                  </div>
                  {/* Ultimate Plan */}
                  <div className={styles.pricingDesign}>
                    <div className={styles.singlePricing}>
                      <div className={styles.priceHead}>
                        <h2>Ultimate</h2>
                        <h1>₹4999</h1>
                        <span>/Monthly</span>
                      </div>
                      <ul>
                        <li><b>15</b> websites</li>
                        <li><b>50GB</b> Disk Space</li>
                        <li><b>50</b> Emails</li>
                        <li><b>50GB</b> Bandwidth</li>
                        <li><b>10</b> Subdomains</li>
                        <li><b>Unlimited</b> Support</li>
                      </ul>
                      <a href="#" className={styles.priceBtn} data-price="₹4999">Order Now</a>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>

      <Footer2 />
    </div>
  );
}

export default Pricing;
