import React , {useState} from 'react';

import Button from '../button/Button';
import Partner from '../partner/Partner';


function About6(props) {

    const [dataBlock] = useState(
        {
            subheading: 'About',
            heading: 'SAP Support Services',

            desc1: "Unlock the full potential of your SAP business suite with our comprehensive support services.  ",
            desc2: 'We provide functional support and maintenance solutions to drive digital innovation and excellence throughout your SAP digital journey.',
        }
    )
    return (
        <section className="about s2">
                    <div className="shape"></div>
                    <div className="shape right"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">    

                                <div className="about__main center" data-aos="fade-up" data-aos-duration="3000">
                                    <img src="https://trigeminal.blr1.cdn.digitaloceanspaces.com/www/Trigeminal.ai/sap-support.png" alt="Trigeminal" />
                                    <p className="mb-17">{dataBlock.desc1}</p>
                                    <p className="mb-30">{dataBlock.desc2}</p>
                                    <h6 className="sub-heading mb-17"><span>How SAP Consulting Helps</span></h6>
                                    <p className="mb-30">{dataBlock.desc3}</p>

                                    <p className="mb-30">{dataBlock.desc4}</p>
                                    <Button title='More About' link='/services' />
                                </div>

                                <Partner />
                            </div>
                        </div>
                    </div>
                </section>
    );
}

export default About6;