


import Implementation from "./Implementation";
import Blog from "./Blog";
import NavService from "./NavService";
import BlogDetails1 from "./BlogDetails1";
import Contact from "./Contact";
import HelpCenter from "./HelpCenter";
import Home01 from "./Home01";
import HanaService from "./HanaService";
import Products from "./Products";
import Partners from "./Partners";
import Services from "./Services";
import SapUpgradation from './SapUpgradation';
import Consulting from "./Consulting"
import Support from "./Support";
import BlogDetails from "./BlogDetails";
import About from "./About";
import Triads from "./Triads";
import Privacy from "./Privacy";
import Terms from "./Terms";
import BlogDetails2 from "./BlogDetails2";
import Page404 from "./Page404";
import LoginPage from "./LoginPage";
import Refund from "./Refund";
import Pricing from "./Pricing";




const routes = [
  { path: '/', component: <Home01 />},
  { path: '/about', component: <About />},
  { path: '/hana-services', component: <HanaService />},
  {path:'/triads', component: <Triads/>},
  {path:'/services', component: <NavService/>},
  { path: '/products', component: <Products />},
  { path: '/blog', component: <Blog />},
  {path:'/upgradation', component: <SapUpgradation/>},
  {path:'/consulting', component:<Consulting/>},
  {path:'/support', component:<Support/>},
  { path: '/blog-details1', component: <BlogDetails />},
  { path: '/blog-details2', component: <BlogDetails1 />},
  { path: '/blog-details3', component: <BlogDetails2 />},
  { path: '/services-sap', component: <Services />},
  { path: '/help-center', component: <HelpCenter />},
  { path: '/partners', component: <Partners />},
  { path: '/implementation', component: <Implementation />},
  { path: '/contact', component: <Contact />},
  { path: '/privacy', component: <Privacy />},
  { path: '/pricing', component: <Pricing />},
  { path: '/terms', component: <Terms />},
  { path: '/refund', component: <Refund />},
  { path: '/login', component: <LoginPage /> },
  { path: '*', component: <Page404 /> }



]

export default routes;