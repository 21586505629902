
import img1 from '../images/layouts/project-01.png'
import img2 from '../images/layouts/project-02.png'
import img3 from '../images/layouts/project-03.png'
import img4 from '../images/layouts/project-04.png'
import img5 from '../images/layouts/project-05.png'

const dataProject1 = [

    {
        id: 1,
        img: img1,
        title: 'Quick Turnaround Time'
    },
    {
        id: 2,
        img: img5,
        title: 'Trusted Creative Partner'
    },
    {
        id: 3,
        img: img3,
        title: 'Creative Advertising Solutions'
    },
    {
        id: 4,
        img: img4,
        title: '1000+ Projects Completed'
    },
    {
        id: 5,
        img: img2,
        title: 'Team with a Variety of Skills'
    },
    


    

]

export default dataProject1;