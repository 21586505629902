
import img1 from '../images/icon/icon1.png'
import img2 from '../images/icon/icon2.png'
import img3 from '../images/icon/icon3.png'
import img4 from '../images/icon/icon4.png'


const dataBox5 = [

    {
        id: 1,
        img: img1,
        title: 'Expert Guidance',
        desc: "Our seasoned team of SAP experts ensures you get the best out of your SAP business suite, gaining a competitive advantage in the market."
    },
    {
        id: 2,
        img: img2,
        title: 'Comprehensive Solutions',
        desc: 'From S/4 HANA integration to data migration, cloud analysis implementation, and ongoing support, we offer a complete suite of SAP support solutions tailored to your business needs.'
    },
    

]

export default dataBox5;