import React from 'react';
import img1 from '../assets/images/layouts/login.png'



function Page404(props) {
    return (
        <>
        <div style={{textAlign:"center",marginTop:"100px"}}>
      <h1>Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>

      <img style={{justifyContent:"center", alignItems:"center", alignContent:"center", width:"400px"}} src={img1} alt="login-img" />
    </div>
        </>
    );
}

export default Page404;