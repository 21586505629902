import React , {useState} from 'react';

import Button from '../button/Button';



function Token2(props) {

    
    return (
        <section className="token">
            <div className="shape"></div>
            <div className="container">
                <div className="row">
                    
                    <div className="col-xl-12 col-md-12">
                        <div className="token__main" data-aos="fade-left" data-aos-duration="2000">
                        <h5 className="heading">End-to-End SAP Support Services: Designed to Map Your Business Needs</h5>
                          
                            <ul style={{marginTop:'50px',}} className="token-list">
                                <li>
                                    <div  className="name ">
                                    <svg width="12" height="16" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path fill="currentColor" d="m9 20.42l-6.21-6.21l2.83-2.83L9 14.77l9.88-9.89l2.83 2.83z"/></svg>

                                    <p>Level 1 Support: Dedicated tools for SAP software management support.</p></div>
                                </li>
                                <li>
                                    <div className="name">
                                    <svg width="12" height="16" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path fill="currentColor" d="m9 20.42l-6.21-6.21l2.83-2.83L9 14.77l9.88-9.89l2.83 2.83z"/></svg>

                                    <p>Level 2 Support: Addressing user issues and providing how-to fixes.</p></div>
                                    
                                </li>
                                <li>
                                    <div className="name">
                                    <svg width="12" height="16" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path fill="currentColor" d="m9 20.42l-6.21-6.21l2.83-2.83L9 14.77l9.88-9.89l2.83 2.83z"/></svg>

                                    <p>Level 3 Support: Expert consultation and guidance throughout the implementation process.</p></div>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    );
}

export default Token2;