
import img1 from '../images/layouts/blog-01.png'
import img2 from '../images/layouts/blog-02.png'
import img3 from '../images/layouts/blog-03.png'

const dataBlog = [

    {
        id: 1,
        img: img1,
        title: 'POWER OF AI IN MEDICAL DIAGNOSTICS',
        time: 'January 21, 2024',
        text: "The medical field is witnessing a transformative shift. From detecting diseases to predicting patient outcomes, AI's impact on medical diagnostics is profound and far-reaching",
        link :'blog-details1'
    },
    {
        id: 2,
        img: img2,
        title: 'DIGITAL ASSET MANAGEMENT (DAM)',
        time: 'February 18, 2024',
        text: 'It has evolved from a simple storage solution to a sophisticated system that streamlines content creation, organization, and distribution',
        link :'blog-details2'
    },
    {
        id: 3,
        img: img3,
        title: 'PREDICTING WITH PRECISION',
        time: 'March 01, 2024',
        text: 'Financial forecasting has always been a blend of art and science. With the advent of AI, the scales are tipping towards science, offering unprecedented accuracy in predictions.',
        link :'blog-details3'
    },
   


]

export default dataBlog;