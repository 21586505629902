
import img1 from '../images/icon/icon1.svg'
import img2 from '../images/icon/icon2.svg'
import img3 from '../images/icon/icon3.svg'
import img4 from '../images/icon/icon4.png'


const dataBox1 = [

    {
        id: 1,
        img: img1,
        title: 'Migration to SAP',
        desc: "Accelerate migration to SAP from an existing ERP without data loss with our end-to-end support services."
    },
    {
        id: 2,
        img: img2,
        title: 'SAP Consulting and Implementation',
        desc: 'Partner with us to develop efficient SAP software tailored to your business needs.'
    },
    {
        id: 3,
        img: img3,
        title: 'Innovation and Advisory Services',
        desc: 'Prepare your business for the implementation of SAP with our expert guidance and support.'
    }
    


]

export default dataBox1;