import React , {useState} from 'react';

import Button from '../button/Button';
import Partner from '../partner/Partner';


function About2(props) {

    const [dataBlock] = useState(
        {
            subheading: 'About',
            heading: 'High-quality SAP Services',

            desc1: "Unlock the full potential of your business with Trigeminal.ai's expert SAP implementation services. ",
            desc2: 'As a leading SAP implementation company in India, we offer tailored solutions to streamline your business processes and drive growth. Discover how SAP implementation can elevate your business',
            desc3:"SAP provides industry-specific modules that can be seamlessly integrated into any business model, regardless of size or location. By implementing SAP, you can simplify departmental management, enhance productivity, and improve communication within your organization. Trigeminal.ai specializes in SAP consulting, support, and implementation services, trusted by some of the world's most prestigious companies."
        }
    )
    return (
        <section className="about s2">
                    <div className="shape"></div>
                    <div className="shape right"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="block-text center pd-0">
                                    <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                    <h3 className="heading pd">{dataBlock.heading}</h3>
                                        
                                </div>

                                <div className="about__main center" data-aos="fade-up" data-aos-duration="3000">
                                    <img src="https://trigeminal.blr1.cdn.digitaloceanspaces.com/www/Trigeminal.ai/sap-implementation.png" alt="Trigeminal" />
                                    <p className="mb-17">{dataBlock.desc1}</p>
                                    <p className="mb-30">{dataBlock.desc2}</p>
                                    <h6 className="sub-heading mb-17"><span>How SAP Implementation Helps:</span></h6>
                                    <p className="mb-30">{dataBlock.desc3}</p>
                                    <Button title='More About Us' link='/upgradation' />
                                </div>

                                <Partner />
                            </div>
                        </div>
                    </div>
                </section>
    );
}

export default About2;