
import img1 from '../images/icon/icon4.svg'
import img2 from '../images/icon/icon5.svg'
import img3 from '../images/icon/icon6.svg'
import img4 from '../images/icon/icon7.svg'
import img5 from '../images/icon/icon8.svg'
import img6 from '../images/icon/icon9.svg'

const dataBox2 = [

    {
        id: 1,
        img: img1,
        title: 'Architecture',
        desc: "Eliminate limitations on business architecture."
    },
    {
        id: 2,
        img: img2,
        title: 'Supply Chain',
        desc: 'Gain complete control over the supply chain.'
    },
    {
        id: 3,
        img: img3,
        title: 'Automation',
        desc: 'Increase efficiency through automation.'
    },
    {
        id: 4,
        img: img4,
        title: 'Data Analysis',
        desc: 'Simplify data reporting and analysis.'
    },
    {
        id: 5,
        img: img5,
        title: 'Communication Channels',
        desc: 'Upgrade internal and external communication channels.'
    },
    {
        id: 6,
        img: img6,
        title: 'Material resources',
        desc: 'Improve management of human and material resources.'
    }
    


]

export default dataBox2;