
import img2 from '../images/layouts/team-02.png'


const dataTeam = [

    {
        id: 1,
        img: "https://trigeminal.blr1.cdn.digitaloceanspaces.com/www/Trigeminal.ai/team-01.png",
        name: 'Dr.  Vysakh Mohan',
        position: 'CEO &  Co-founder',
        link1:'https://www.linkedin.com/in/drvysakhmohan/',
        link2:'https://www.facebook.com/vysakhmohanm',
        link3:'https://api.whatsapp.com/send/?phone=918921541862'
    },
    {
        id: 2,
        img: img2,
        name: 'Mohan M J',
        position: 'CTO, Co-founder',
        link1:'https://www.linkedin.com/in/mohan-mj/',
        link3:"https://api.whatsapp.com/send/?phone=918310217802"
    },

   

    

]

export default dataTeam;